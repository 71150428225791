import React, {FC, useState} from 'react'
import cn from 'classnames'
import Image from 'next/image'
import styles from './News.module.scss'
import stylesSlider from '../ProductsSlider/ProductsSlider.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import { dataNews } from "./staticData";
import useWindowDimensions from "../../../customHooks/useWindowDimension";

type NewsProps = {
  errorPage?: boolean
  containerScaling?: string
}

const News:FC<NewsProps> = ({errorPage, containerScaling}) => {

  const [paginationRef, setPaginationRef] = useState<HTMLElement | null>(null)
  const { width } = useWindowDimensions()

  return (
    <section className={cn(styles.sectionMain, {[styles.sectionMainError]: errorPage}, containerScaling)}>
      <div className={styles.sectionMainContainer}>
        <div className={styles.sectionMainHeader}>
          {errorPage ? null
              : <div className={styles.sectionMainText}>Dive into our</div>
          }
          {errorPage
              ? <div className={styles.sectionHeaderError}>News &  useful articles</div>
              : <div className={styles.sectionMainHeadline}>Library of Tips on Well-Being</div>
          }
        </div>
        {width > 1024 ?
            <div className={styles.sectionMainBody}>
              <div className={styles.sectionCarousel}>
                <div className={styles.sectionCarouselList}>
                  <div className={styles.sectionCarouselItem}>
                    <a className={styles.newsCard}
                       href="https://blog.vitaliv.us/your-longevity/food-supplements-obvious-or-questionable/"
                    >
                      <div className={styles.newsCardPic}>
                    <span className={styles.newsCardImg}>
                      <Image
                          src="/images/aboutUs/new1.jpeg"
                          alt="news1"
                          width={368}
                          height={380}
                          objectFit="cover"
                      />
                    </span>
                      </div>
                      <div className={styles.newsCardInfo}>
                        <div className={styles.newsCardHeadline}>
                          Dieraty Supplements – Obvious or Questionable?
                        </div>
                        <div className={styles.newsCardText}>
                          It puzzles many of us, doesn’t it? Yet this question
                          often receives either wrong or ambiguous answers.
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className={styles.sectionCarouselItem}>
                    <a className={styles.newsCard}
                       href="https://blog.vitaliv.us/your-longevity/the-science-of-ageing-what-you-need-to-learn-today-not-to-be-afraid-of-old-age/"
                    >
                      <div className={styles.newsCardPic}>
                    <span className={styles.newsCardImg}>
                      <Image
                          src="/images/aboutUs/new2.jpeg"
                          alt="news2"
                          width={368}
                          height={380}
                          objectFit="cover"
                      />
                    </span>
                      </div>
                      <div className={styles.newsCardInfo}>
                        <div className={styles.newsCardHeadline}>
                          The science of ageing: what you need to learn
                          today not to be afraid of old age
                        </div>
                        <div className={styles.newsCardText}>
                          With every new decade, life expectancy throughout
                          most of the countries in our world is growing.
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className={styles.sectionCarouselItem}>
                    <a className={styles.newsCard}
                       href="https://blog.vitaliv.us/health-goals/cholesterol-benefits-and-side-effects/"
                    >
                      <div className={styles.newsCardPic}>
                    <span className={styles.newsCardImg}>
                      <Image
                          src="/images/aboutUs/new3.jpeg"
                          alt="news3"
                          width={368}
                          height={380}
                          objectFit="cover"
                      />
                    </span>
                      </div>
                      <div className={styles.newsCardInfo}>
                        <div className={styles.newsCardHeadline}>
                          Cholesterol: benefits and side effects
                        </div>
                        <div className={styles.newsCardText}>
                          We challenge every myth about the most dangerous food
                          compound – we are going to tell you how cholesterol benefits the body.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className={stylesSlider.sectionMainBody}>
              <div className={stylesSlider.slider}>
                <Swiper
                    className={stylesSlider.sectionCarousel}
                    spaceBetween={20}
                    modules={[Pagination]}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      // when window width is >= 640px
                      640: {
                        slidesPerView: 2,
                      },
                      // when window width is >= 860px
                      860: {
                        slidesPerView: 3,
                      },
                    }}
                    pagination={{
                      el: paginationRef,
                      clickable: true,
                      bulletClass: stylesSlider.sectionCarouselBullet,
                      bulletActiveClass: cn(stylesSlider.sectionCarouselBulletActive),
                    }}
                    loop
                    slidesPerView={1}
                >
                  {dataNews.map((item) => {
                    return (
                        <SwiperSlide
                            key={item.id}
                            className={stylesSlider.sectionCarouseItem}
                        >
                          <div className={styles.sectionCarouselItem}>
                            <a className={styles.newsCard}
                               href={item.link}
                            >
                              <div className={styles.newsCardPic}>
                              <span className={styles.newsCardImg}>
                                <Image
                                    src={item.src}
                                    alt="news"
                                    width={368}
                                    height={380}
                                    objectFit="cover"
                                />
                              </span>
                              </div>
                              <div className={styles.newsCardInfo}>
                                <div className={styles.newsCardHeadline}>
                                  {item.text}
                                </div>
                                <div className={styles.newsCardText}>
                                  {item.subText}
                                </div>
                              </div>
                            </a>
                          </div>
                        </SwiperSlide>
                    )
                  })}
                </Swiper>
                <div
                    ref={(node) => setPaginationRef(node)}
                    className={cn(stylesSlider.sectionCarouselPagination)}
                >
                  {dataNews.map((el, idx) => (
                      <span key={idx} className={stylesSlider.sectionCarouselBullet}></span>
                  ))}
                </div>
              </div>
            </div>
        }

        <div className={styles.sectionMainFooter}>
          {errorPage
              ? < a className = {cn(styles.sectionMainMore, styles.buttonArrow)} href="https://blog.vitaliv.us/">
                 Read more articles
               </a>
              : < a className = {styles.sectionMainMore} href="https://blog.vitaliv.us/">
                 Read more articles
                </a>
          }
        </div>
      </div>
    </section>
  )
}

export default News
