export const dataNews = [
    {
        id: 0,
        text: 'Dieraty Supplements – Obvious or Questionable?',
        subText: 'It puzzles many of us, doesn’t it? Yet this question often receives either wrong or ambiguous answers.',
        src: '/images/aboutUs/new1.jpeg',
        link: 'https://blog.vitaliv.us/your-longevity/food-supplements-obvious-or-questionable/'
    },
    {
        id: 1,
        text: 'The science of ageing: what you need to learn today not to be afraid of old age',
        subText: 'With every new decade, life expectancy throughout most of the countries in our world is growing.',
        src: '/images/aboutUs/new2.jpeg',
        link: 'https://blog.vitaliv.us/your-longevity/the-science-of-ageing-what-you-need-to-learn-today-not-to-be-afraid-of-old-age/'
    },
    {
        id: 2,
        text: 'Cholesterol: benefits and side effects',
        subText: 'We challenge every myth about the most dangerous food compound – we are going to tell you how cholesterol benefits the body.',
        src: '/images/aboutUs/new3.jpeg',
        link: 'https://blog.vitaliv.us/health-goals/cholesterol-benefits-and-side-effects/'
    }
]
